import debug from './common/utilities/debug'
import initializeFlow from './initializeFlow'
import updateFlow from './updateFlow'

const methods = {
  init: initializeFlow,
  update: updateFlow,
}

function flowbox(method, settings) {
  if (!methods[method]) throw new TypeError(`[Flowbox]: No such method "${method}"`)

  if (method === 'init') {
    const debugInit = debug('init')
    const { container, key, lazyLoad, locale, allowCookies } = settings || {}
    debugInit('NODE_ENV:', process.env.NODE_ENV)
    debugInit('__BUILD__:', __BUILD__)
    debugInit('__HOSTNAME__:', __HOSTNAME__)
    debugInit('__EXPERIENCE_ENDPOINT__:', __EXPERIENCE_ENDPOINT__)
    debugInit('__API_ENDPOINT__:', __API_ENDPOINT__)
    debugInit('Container:', container)
    debugInit('Key:', key)
    debugInit('Lazy load:', lazyLoad)
    debugInit('Locale:', locale)
    debugInit('allowCookies:', allowCookies)
  }

  return methods[method](settings)
}

let queuedCalls = []
if (window.flowbox && window.flowbox.q) {
  queuedCalls = window.flowbox.q
}

queuedCalls.forEach(queuedCall => {
  flowbox(...queuedCall)
})

window.flowbox = flowbox
window.flowbox.q = []

export default flowbox
